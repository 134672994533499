<mat-drawer-container class="side-nav-container" [hasBackdrop]="true">
  <mat-drawer-content class="content">
    <ng-content></ng-content>
  </mat-drawer-content>

  <mat-drawer #sidePanel mode="over" position="end" (closed)="closedSidePanel.next()" (closedStart)="onDrawerClosed()">
    <div class="content">
      <ng-content select="[side-panel]"></ng-content>
    </div>
  </mat-drawer>
</mat-drawer-container>
<div class="overlay" *ngIf="spinner.loading | async">
  <mat-spinner diameter="50"></mat-spinner>
</div>
