<div class="select" #inputBox (click)="focusOnSearchInput()" *ngIf="multiple">
    <div class="label" *ngIf="!selectedOptions.length && !isSearchFocused">{{label}}</div>
    <div class="selected-multiple-options">
        <div class="option" *ngFor="let selectedOpt of selectedOptions">
            <ng-container *ngIf="maxTagChars !== undefined; else unlimitedTag">
                {{selectedOpt[displayField] | slice:0:maxTagChars}}...
            </ng-container>
            <ng-template #unlimitedTag>
                {{selectedOpt[displayField]}}
            </ng-template>
            <i (click)="onClickOption(selectedOpt, $event)" class="fa-solid fa-circle-xmark"></i>
        </div>
    </div>
    <input *ngIf="showSearchInput" #searchInput type="text" [formControl]="search" class="search-input" (blur)="onSearchBlur()">
</div>

<div class="select" #inputBox (click)="focusOnSearchInput()" *ngIf="!multiple" [class.disabled]="disabled">
    <div class="label" *ngIf="!selectedOptions.length && !isSearchFocused">{{label}}</div>
    <div class="selected-option" *ngIf="selectedOptions.length && !isSearchFocused && !isOpenPanel">
        {{selectedOptions[0][displayField]}}
    </div>
    <input *ngIf="showSearchInput" #searchInput type="text" [formControl]="search" class="search-input" (blur)="onSearchBlur()">
</div>

<div class="options-panel" *ngIf="isOpenPanel" [style]="{width: optionsPanelWidth + 'px'}">
    <div *ngIf="isLoading" class="loading">
        <mat-spinner [diameter]="20"></mat-spinner>
    </div>
    <ng-container *ngFor="let option of filteredOptions">
        <div class="option" [class.selected]="isSelected(option)" (click)="onClickOption(option, $event)">
            <i class="fa-regular fa-square-check"></i>
            {{option[displayField]}}
        </div>
    </ng-container>
</div>
