import { inject } from "@angular/core";
import { UserService } from "./user.service";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { firstValueFrom } from "rxjs";

export const roleGuard: CanActivateFn = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const userService = inject(UserService);
    const router = inject(Router);
    const requiredPermissions = route.data['requiredPermissions'] as string[];
    if (!requiredPermissions || requiredPermissions.length === 0) {
        return true;
    }
    const hasPermission = await firstValueFrom(userService.hasAnyPermission(requiredPermissions));
    if (hasPermission) {
        return true;
    }
    return router.createUrlTree(['/not-allowed']);
};
