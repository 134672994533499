<div class="wrapper" [style]="{color: color}" (mouseleave)="clearHighlight()">
 <span class="star" (mouseenter)="highlightTo(1)" (click)="setRate(1)">
  <i *ngIf="isPositionEmpty(1)" class="fa-regular fa-star"></i>
  <i *ngIf="!isPositionEmpty(1)" class="fa-solid fa-star"></i>
</span>
  <span class="star" (mouseenter)="highlightTo(2)" (click)="setRate(2)">
  <i *ngIf="isPositionEmpty(2)" class="fa-regular fa-star"></i>
  <i *ngIf="!isPositionEmpty(2)" class="fa-solid fa-star"></i>
</span>

  <span class="star" (mouseenter)="highlightTo(3)" (click)="setRate(3)">
  <i *ngIf="isPositionEmpty(3)" class="fa-regular fa-star"></i>
  <i *ngIf="!isPositionEmpty(3)" class="fa-solid fa-star"></i>
</span>
  <span class="star" (mouseenter)="highlightTo(4)" (click)="setRate(4)">
  <i *ngIf="isPositionEmpty(4)" class="fa-regular fa-star"></i>
  <i *ngIf="!isPositionEmpty(4)" class="fa-solid fa-star"></i>
</span>
  <span class="star" (mouseenter)="highlightTo(5)" (click)="setRate(5)">
  <i *ngIf="isPositionEmpty(5)" class="fa-regular fa-star"></i>
  <i *ngIf="!isPositionEmpty(5)" class="fa-solid fa-star"></i>
</span>
</div>
