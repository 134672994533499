<h1 mat-dialog-title>{{data.title}}</h1>
<div class="warning-dialog-container-text" mat-dialog-content>
  <div class="warning">
    <p class="pre-line">
      {{data.text}}
    </p>
  </div>
  <mat-dialog-actions align="end">
    <button mat-raised-button color="primary" (click)="accept()" *ngIf="data.buttonAcceptText"> {{ data.buttonAcceptText }}</button>
    <button mat-button mat-dialog-close (click)="cancel()"> {{ data.buttonCancelText || 'Cancelar' }}</button>
  </mat-dialog-actions>
</div>
