import { inject } from "@angular/core"
import { AuthService } from "@auth0/auth0-angular";
import { firstValueFrom } from "rxjs";

export const authGuard = async () => {
    const authService = inject(AuthService);

    const isAuthenticated = await firstValueFrom(authService.isAuthenticated$); 
    if (isAuthenticated) {
        return true;
    } else {
        authService.loginWithRedirect();
        return false;
    }
}