
<i class="close-icon fa-solid fa-circle-xmark" *ngIf="file" (click)="removeSelectedFile()"></i>
<div class="selector" [class.disabled]="disabled" [class.selected]="!!file" (click)="openFileSelector()">
    <p *ngIf="!file">
      <mat-icon>upload</mat-icon>
      {{ label }}
    </p>
    <p *ngIf="file">{{ file.name }}</p>
</div>
<input hidden #fileInput type="file" (change)="onSelectFile($event)">
