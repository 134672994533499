import { Component, ElementRef, HostListener, Input, OnInit, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, debounceTime, filter, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-async-search-select',
  templateUrl: './async-search-select.component.html',
  styleUrls: ['./async-search-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AsyncSearchSelectComponent),
      multi: true
    }
  ],
})
export class AsyncSearchSelectComponent implements OnInit, ControlValueAccessor {

  @HostListener('document:click', ['$event']) clickout(event: any) {
    if (!this.elm.nativeElement.contains(event.target) && !event.target.classList.contains('fa-circle-xmark')) {
      this.closePanel();
      this.clearFilteredOptions();
    }
  }
  @ViewChild('searchInput') select!: ElementRef<HTMLInputElement>;
  @ViewChild('inputBox') inputBox!: ElementRef<HTMLInputElement>;

  @Input() label: string = 'Seleccionar';
  @Input() displayField!: string;
  @Input() selectedOptions: any[] = [];
  @Input() minCharsToSearch: number = 3;
  @Input() maxTagChars: number | undefined;
  @Input() searchFunc!: (search: string) => Observable<any[]>;
  @Input() multiple: boolean = false;

  isOpenPanel: boolean = false;
  optionsPanelWidth: number = 0;

  isSearchFocused: boolean = false;
  showSearchInput: boolean = false;

  search = new FormControl<string>('');
  isLoading: boolean = false;

  filteredOptions: any[] = [];

  disabled: boolean = false;

  constructor(private elm: ElementRef){}


  onChange: (value: any) => void = (_: any) => { };
  onTouched: () => void = () => { };

  writeValue(value: any): void {
    if (!value) {
      this.selectedOptions = [];
      return;
    }

    if (Array.isArray(value)) {
      if (!this.multiple) {
        throw new Error('On AsyncSearchSelectComponent, when multiple is false, the value must be an object, not an array');
      }
      this.selectedOptions = value;
    } else if (typeof value === 'object') {
      if (this.multiple) {
        throw new Error('On AsyncSearchSelectComponent, when multiple is true, the value must be an array, not an object');
      }
      this.selectedOptions = [value];
    } else {
      this.selectedOptions = [];
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }


  ngOnInit(): void {
    if (!this.displayField) {
      throw new Error('displayField is required');
    }
    if (!this.searchFunc) {
      throw new Error('searchFunc is required');
    }

    this.search.valueChanges
    .pipe(
      tap((search) => {
        if (search?.trim().length === 0) {
          this.filteredOptions = [];
        }
      }),
      filter(search => (!!search && (search.trim().length >= this.minCharsToSearch))),
      debounceTime(300),
      tap((search) => {
        this.openPanel();
        this.isLoading = true;
      }),
      switchMap(search => this.searchFunc(search || ''))
    )
    .subscribe(result => {
      this.isLoading = false;
      this.filteredOptions = result;
    });
  }

  focusOnSearchInput() {
    this.showSearchInput = true;
    setTimeout(() => {
      this.select.nativeElement.focus();
      this.isSearchFocused = true;
    }, 0);
  }

  clearFilteredOptions() {
    this.search.setValue('');
    this.filteredOptions = [];
  }

  onClickOption(option: any, event: Event) {
    event.stopPropagation();
    event.preventDefault();

    if (this.multiple) {
      if (this.isSelected(option)) {
        this.selectedOptions = this.selectedOptions.filter(o => o[this.displayField] !== option[this.displayField]);
      } else {
        this.selectedOptions.push(option);
      }
      this.onChange(this.selectedOptions);
    } else {
      this.selectedOptions = [option];
      this.onChange(this.selectedOptions[0]);
      this.clearFilteredOptions();
      this.closePanel();
    }
  }

  isSelected(option: any) {
    return this.selectedOptions.some(o => o[this.displayField] === option[this.displayField]);
  }

  openPanel() {
    this.optionsPanelWidth = this.inputBox.nativeElement.offsetWidth;
    this.isOpenPanel = true;
  }
  closePanel() {
    this.isOpenPanel = false;
  }

  onSearchBlur() {
    this.showSearchInput = false;
    this.isSearchFocused = false;
  }
}
