<a [ngClass]="{'side-nav-item-wrapper': true, 'disabled': disabled, 'subgroup-item': isInSubGroup }" [routerLink]="route" routerLinkActive="selected" [routerLinkActiveOptions]="{exact: false}">
  <span>{{title}}</span>
  <mat-icon *ngIf="isInSubGroup">arrow_right</mat-icon>
</a>

<div #hoverContent class="hover-content">
  <ng-content></ng-content>
</div>


