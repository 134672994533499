<mat-form-field>
    <mat-label>{{label}}</mat-label>
    <input matInput [formControl]="filter" [matAutocomplete]="complete" />
    <mat-autocomplete #complete="matAutocomplete" [displayWith]="displayFn.bind(this)">
        <mat-option *ngFor="let option of filteredOptions" [value]="option">
            <ng-container *ngIf="optionTemplate">
                <ng-container [ngTemplateOutlet]="optionTemplate" [ngTemplateOutletContext]="{option}"></ng-container>
            </ng-container>
            <ng-container *ngIf="!optionTemplate">
                {{option[displayField]}}
            </ng-container> 
        </mat-option>
    </mat-autocomplete>
</mat-form-field>