import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterLink } from "@angular/router";

@Component({
  selector: 'app-side-nav-item-floating',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './side-nav-item-floating.component.html',
  styleUrls: ['./side-nav-item-floating.component.scss']
})
export class SideNavItemFloatingComponent implements OnInit {
  @Input() items: { category: string, title: string, route: string }[] = [];

  constructor() {}

  ngOnInit() {}

}
