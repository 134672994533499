import {AfterContentInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import {AuthService} from '@auth0/auth0-angular';
import { set } from 'date-fns';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent  {
  constructor(public auth: AuthService) {}

  logout() {
    this.auth.logout({logoutParams: { returnTo: document.location.origin }});
  }
}
