import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-select-autocomplete',
  templateUrl: './select-autocomplete.component.html',
  styleUrls: ['./select-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectAutocompleteComponent),
      multi: true
    }
  ]
})
export class SelectAutocompleteComponent implements OnInit, OnChanges, ControlValueAccessor {

  @Input() label!: string;
  @Input() placeholder!: string;
  @Input() options!: any[];
  @Input() displayField!: string;
  @Input() valueField?: string;

  filter: FormControl = new FormControl('');
  filteredOptions: any[] = [];

  @Input() optionTemplate: TemplateRef<any> | null = null;
  @Input() displayFn: (_: any) => string = (option: any) => option ? option[this.displayField] : '';

  isDisabled: boolean = false;
  onChange = (_: any) => { };
  onTouch = () => { };

  constructor() { }

  ngOnInit(): void {
    this.filteredOptions = this.options;
    this.filter.valueChanges.subscribe(value => {
      if (!value) {
        this.filteredOptions = this.options;
        this.onChange(null);
        return;
      }
      if (typeof value === 'string') {
        if (value.trim() === '') {
          this.filteredOptions = this.options;
          this.onChange(null);
          return;
        }
        this.filteredOptions = this.options.filter(option => this.displayFn(option).toLowerCase().includes(value.toLowerCase()));
        this.onChange(null);
        return
      }
      this.onChange(this.valueField ? value[this.valueField] : value);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options']) {
      this.filteredOptions = this.options;
    }
  }

  writeValue(obj: any): void {
    let value = obj;
    if (this.valueField) {
      value = this.options.find(option => option[this.valueField!] === obj);
    }
    if (value) {
      this.filter.setValue(value, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    if (isDisabled) {
      this.filter.disable();
    } else {
      this.filter.enable();
    }
  }
}
