import { Component, ContentChildren, HostBinding, Input, OnInit, QueryList } from '@angular/core';
import { SideNavItemComponent } from '../side-nav-item/side-nav-item.component';
import {SideNavItemSubgroupComponent} from "../side-nav-item-subgroup/side-nav-item-subgroup.component";
import {Subject} from "rxjs";

@Component({
  selector: 'app-side-nav-item-group',
  templateUrl: './side-nav-item-group.component.html',
  styleUrls: ['./side-nav-item-group.component.scss'],
})
export class SideNavItemGroupComponent implements OnInit {

  @ContentChildren(SideNavItemComponent) items!: QueryList<SideNavItemComponent>;
  @ContentChildren(SideNavItemSubgroupComponent) subgroups!: QueryList<SideNavItemSubgroupComponent>;

  @HostBinding('class.open') get isOpen() {
    return this.status === 'open';
  }

  @HostBinding('class.collapsed') get isCollapsedClass() {
    return this.isCollapsed;
  }

  @Input() title: string = '';
  @Input() disabled: boolean = false;
  @Input() set icon(icon: string) {
    this._iconClass = `fa-${icon}`;
  }
  @Input() isCollapsed: boolean = false;
  @Input() status: 'open' | 'closed' = 'closed';

  _iconClass: string | null = null;

  constructor() { }

  ngOnInit(): void {}

  public hasItemWithRoute(route: string): boolean {
    const foundInSubgroups = this.subgroups.some(subGroup =>
      subGroup.items.some(item => route.includes(item.route))
    );
    return this.items.some(item => route.includes(item.route)) || foundInSubgroups;
  }

  onClickHeader(event: MouseEvent): void {
    this.status = this.status === 'open' ? 'closed' : 'open';
  }

  getColorStyle(): { color: string } {
    return {
      color: (this.isCollapsed && this.status == 'open') ? '#F2F2F2' : '#48B9CE'
    };
  }
}
