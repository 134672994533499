// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  apis: {
    cloudFunctions: 'https://us-central1-big-bison-734.cloudfunctions.net',
    jobs: 'https://devel-apijobs.ilerna.com/api/v1',
    exams: 'https://pre-apiexam.ilernaonline.com/api/v1',
    reviews: 'https://devel-apireview.ilernaonline.com/api/v1',
    mailing: 'https://awstest2.ilernaonline.com/api/v1',
    fct: 'https://devel-apifct.ilernaonline.com/api/v1',
    fct_v2: 'https://devel-apifct.ilernaonline.com/api/v2',
    web: 'https://preprod.ilerna.es',
    content: 'https://devel-apicontent.ilernaonline.com/api/v1',
    logistics: 'https://pre-api-logistics.ilerna.com/api/v1',
    notices: 'https://pre-apinotice.ilerna.com/api/v1',
    cis: 'https://pre-apicis.ilernaonline.com/api/v1',
    ais: 'https://pre-apiais.ilernaonline.com/api/v1',
    sis: 'https://preprodsis.ilernaonline.com/api/v2',
    amazon: 'https://isrqs2pu4f.execute-api.eu-central-1.amazonaws.com'
  },

  amazonApiKey: 'H6I6useqTB3axLDd4wTwp1uFkkQk97LZ48700J0l'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
