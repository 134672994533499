<header (click)="onClickSubHeader($event)">
  <div class="title-wrapper">
    <div class="title">
      <span *ngIf="!isCollapsed">{{ title }}</span>
    </div>
    <i *ngIf="!isCollapsed" class="collapse-icon fa-solid fa-caret-down"></i>
  </div>
</header>
<div class="content">
  <ng-content></ng-content>
</div>
