import {NgModule, LOCALE_ID} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import '@angular/common/locales/global/es'

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthHttpInterceptor, AuthModule} from '@auth0/auth0-angular';
import {LoginPageComponent} from './pages/login-page/login-page.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LayoutModule} from './layout/layout.module';
import {DashboardPageComponent} from './pages/dashboard-page/dashboard-page.component';
import {SharedModule} from './shared/shared.module';
import {QuillModule} from 'ngx-quill';
import { NotAllowedPageComponent } from './pages/not-allowed-page/not-allowed-page.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginPageComponent,
    DashboardPageComponent,
    NotAllowedPageComponent,
  ],
  imports: [
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    AuthModule.forRoot({
      domain: 'dev-ksvxn9p0.eu.auth0.com',
      clientId: '0fL9rDLAlF3mq60MbmDH5LxVOTmusvrQ',
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: 'https://core2.ilernaonline.com',
      },
      httpInterceptor: {
        allowedList: [
          {
            uriMatcher: (url) => {
              return !/preprod\.ilerna\.es/.test(url);
            },
            tokenOptions: {
              authorizationParams: {
                audience: 'https://core.ilernaonline.com/'
              }
            }
          }
        ]
      }
    }),
    LayoutModule,
    QuillModule.forRoot()
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true},
    {provide: LOCALE_ID, useValue: 'es-ES'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
