import {NgModule} from '@angular/core';
import {MainLayoutComponent} from './main-layout/main-layout.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {RouterModule} from '@angular/router';
import {SideNavComponent} from './components/side-nav/side-nav.component';
import {SideNavItemGroupComponent} from './components/side-nav-item-group/side-nav-item-group.component';
import {SharedModule} from '../shared/shared.module';
import { SectionWithSidePanelComponent } from './components/section-with-side-panel/section-with-side-panel.component';
import { SectionComponent } from './components/section/section.component';
import { SideNavItemComponent } from './components/side-nav-item/side-nav-item.component';
import {SideNavItemSubgroupComponent} from "./components/side-nav-item-subgroup/side-nav-item-subgroup.component";
import {
  SideNavItemFloatingComponent
} from "./components/side-nav-item-floating/side-nav-item-floating.component";

@NgModule({
  declarations: [
    MainLayoutComponent,
    SideNavComponent,
    SideNavItemGroupComponent,
    SideNavItemComponent,
    SectionWithSidePanelComponent,
    SectionComponent,
  ],
  exports: [
    SectionComponent,
    SectionWithSidePanelComponent
  ],
  imports: [
    SharedModule,
    RouterModule,
    MatSidenavModule,
    SideNavItemSubgroupComponent,
    SideNavItemFloatingComponent
  ]
})
export class LayoutModule {
}
