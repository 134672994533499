import { AfterViewInit, ChangeDetectorRef, Component, HostBinding, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { SideNavItemGroupComponent } from '../side-nav-item-group/side-nav-item-group.component';
import { SideNavItemSubgroupComponent } from "../side-nav-item-subgroup/side-nav-item-subgroup.component";
interface MenuItem {
  category: string;
  title: string;
  route: string;
}

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, AfterViewInit {

  @ViewChildren(SideNavItemGroupComponent) groups!: QueryList<SideNavItemGroupComponent>;
  @ViewChildren(SideNavItemSubgroupComponent) subgroups!: QueryList<SideNavItemSubgroupComponent>;

  @HostBinding('class') get classes() {
    return this.isCollapsed ? 'collapsed' : '';
  };

  public isCollapsed: boolean;
  public dynamicMenuItems: MenuItem[] = [];
  private currentOpenGroup: SideNavItemGroupComponent | null = null;


  constructor(public userSrv: UserService, private router: Router, private cd: ChangeDetectorRef) {
    this.isCollapsed = localStorage.getItem('sideNavCollapsed') === 'true';
    this.openActiveGroupOnNavigate(router);
  }

  ngOnInit(): void {
    this.loadMenuItems();
  }

  ngAfterViewInit(): void {
    const url = this.router.url;
    this.openActiveGroup(url);
    this.cd.detectChanges();
  }

  loadMenuItems(): void {
    this.dynamicMenuItems = [];
    this.userSrv.hasPermission('web:blog_links:').subscribe(hasPermission => {
      if (hasPermission) {
        this.dynamicMenuItems.push({
          category: 'blog_links',
          title: 'Blog link hijo 1',
          route: 'fct/contracts/1'
        });
        this.dynamicMenuItems.push({
          category: 'blog_links',
          title: 'Blog link hijo 2',
          route: 'fct/contracts/2'
        });
        this.dynamicMenuItems.push({
          category: 'blog_links',
          title: 'Blog link hijo 3',
          route: 'fct/contracts/3'
        });
      }
    });
    this.userSrv.hasPermission('web:banners:').subscribe(hasPermission => {
      if (hasPermission) {
        this.dynamicMenuItems.push({
          category: 'web_banners',
          title: 'Web Banner hijo 1',
          route: 'fct/banner/1'
        });
      }
    });
  }

  private openActiveGroupOnNavigate(router: Router) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.groups ) {
          const url = event.urlAfterRedirects;
          this.openActiveGroup(url);
        }
      }
    });
  }

  private openActiveGroup(url: string) {
    const groupWithRoute = this.groups.find(group => group.hasItemWithRoute(url));
    if (groupWithRoute) {
      // Si es un grupo diferente al que estaba previamente abierto, cerramos el grupo anterior
      if (this.currentOpenGroup && this.currentOpenGroup !== groupWithRoute) {
        this.currentOpenGroup.status = 'closed';
      }
      // Abrimos el nuevo grupo
      groupWithRoute.status = 'open';
      this.currentOpenGroup = groupWithRoute;

    }
  }

  toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
    localStorage.setItem('sideNavCollapsed', this.isCollapsed.toString());
  }

  filterItemsByCategory(category: string): MenuItem[] {
    return this.dynamicMenuItems.filter(item => item.category === category);
  }
}
