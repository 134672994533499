<h1 mat-dialog-title>{{data.title}}</h1>
<mat-dialog-content>
  <p>
    {{data.text}}
  </p>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-flat-button (click)="accept()" color="primary">Aceptar</button>
  <button mat-flat-button (click)="cancel()">Cancelar </button>
</mat-dialog-actions>

