import { Injectable } from '@angular/core';
import {AuthService, User} from '@auth0/auth0-angular';
import {BehaviorSubject, filter, map, Observable, pipe, pluck, take} from 'rxjs';
import {JwtHelperService} from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public permissions$: BehaviorSubject<string[] | null> = new BehaviorSubject<string[] | null>(null);
  public roles$: BehaviorSubject<string[] | null> = new BehaviorSubject<string[] | null>(null);

  constructor(private auth: AuthService) {
    auth.getAccessTokenSilently().pipe(
      take(1),
      this.decodeToken(),
    ).subscribe(token => {
      this.permissions$.next(token.permissions);
      this.roles$.next(token['https://core.ilernaonline.com/roles']);
    })
  }

  public hasPermission(permission: string): Observable<boolean> {
    return this.permissions$.pipe(
      filter(permissions => permissions !== null),
      take(1),
      map(permissions => this.includePermission(permissions!, permission))
    );
  }

  public hasAnyPermission(permissionsToCheck: string[]): Observable<boolean> {
    return this.permissions$.pipe(
      filter(permissions => permissions !== null),
      take(1),
      map(userPermissions => permissionsToCheck.some(permission => this.includePermission(userPermissions!, permission)))
    );
  }

  private includePermission(permissions: string[], permissionToCheck: string): boolean {
    const expression = new RegExp(permissionToCheck);
    return permissions.some(permission => expression.test(permission));
  }

  private decodeToken() {
    return map((token: string) => {
      const helper = new JwtHelperService();
      return helper.decodeToken<any>(token);
    });
  }
}
