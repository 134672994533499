import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MainLayoutComponent} from './layout/main-layout/main-layout.component';
import {DashboardPageComponent} from './pages/dashboard-page/dashboard-page.component';
import { authGuard } from './services/auth.guard';
import { roleGuard } from './services/role.guard';
import { NotAllowedPageComponent } from './pages/not-allowed-page/not-allowed-page.component';

const routes: Routes = [
  {
    path: '', component: MainLayoutComponent,
    canActivate: [authGuard],
    canActivateChild: [roleGuard],
    children: [
      { path: 'dashboard', component: DashboardPageComponent},
      { path: 'fct',
        loadChildren: () => import('./sections/fct/fct.module').then(m => m.FctModule),
        data: {requiredPermissions: ['fct:']},
        canActivateChild: [roleGuard],
      },
      { path: 'jobs',
        loadChildren: () => import('./sections/jobs/jobs.module').then(m => m.JobsModule),
        data: {requiredPermissions: ['jobs:']},
        canActivateChild: [roleGuard]
      },
      { path: 'exams',
        loadChildren: () => import('./sections/exams/exams.module').then(m => m.ExamsModule),
        data: {requiredPermissions: ['exams:']},
        canActivateChild: [roleGuard],
      },
      { path: 'reviews',
        loadChildren: () => import('./sections/reviews/reviews.module').then(m => m.ReviewsModule),
        data: {requiredPermissions: ['reviews:']},
      },
      { path: 'finance',
        loadChildren: () => import('./sections/finance/finance.module').then(m => m.FinanceModule),
        data: {requiredPermissions: ['finance:']},
      },
      { path: 'remove-user-data', 
        loadChildren: () => import('./sections/remove-user-data/remove-user-data.module').then(m => m.RemoveUserDataModule),
        data: {requiredPermissions: ['remove-user-data']},
      },
      { path: 'mailing',
        loadChildren: () => import('./sections/mailing/mailing.module').then(m => m.MailingModule),
        data: {requiredPermissions: ['mailing:']},
        canActivateChild: [roleGuard],
      },
      { path: 'web',
        loadChildren: () => import('./sections/web/web.module').then(m => m.WebModule),
        data: {requiredPermissions: ['web:']},
        canActivateChild: [roleGuard],
      },
      { path: 'notices',
        loadChildren: () => import('./sections/notices/notices.module').then(m => m.NoticesModule),
        data: {requiredPermissions: ['press:']},
      },
      { path: 'courses',
        loadChildren: () => import('./sections/courses/courses.module').then(m => m.CoursesModule),
        data: {requiredPermissions: ['course_management:']},
        canActivateChild: [roleGuard],
      },
      { path: 'academic',
        loadChildren: () => import('./sections/academic/academic.module').then(m => m.AcademicModule),
        data: {requiredPermissions: ['academic_configuration:']},
        canActivateChild: [roleGuard],
      },
      { path: 'centers',
        loadChildren: () => import('./sections/centers/centers.module').then(m => m.CentersModule),
        data: {requiredPermissions: ['centers:']},
        canActivateChild: [roleGuard]
      },
      { path: 'contents',
        loadChildren: () => import('./sections/contents/contents.module').then(m => m.ContentsModule),
        data: {requiredPermissions: ['contents:']},
      },
      { path: 'blacklist-calls',
        loadChildren: () => import('./sections/blacklist-calls/blacklist-calls.module').then(m => m.BlacklistCallsModule),
        data: {requiredPermissions: ['sales:blacklist']},
      },
      { path: 'not-allowed', component: NotAllowedPageComponent},
      { path: 'logistics', loadChildren: () => import('./sections/logistics/logistics.module').then(m => m.LogisticsModule) },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
