<div class="side-nav">
  <app-side-nav></app-side-nav>
</div>
<div class="main-section">
  <div class="top-header">
    <div class="account-info" *ngIf="auth.user$ | async as user">
      <img [src]="user.picture" />
      <div class="data">
        <span class="name">{{ user.name }}</span>
        <span class="email">{{ user.email }}</span>
      </div>
      <div class="logout-btn" (click)="logout()">
        <i class="fa-solid fa-right-from-bracket"></i>
      </div>
    </div>
  </div>
  <div class="content scroll-drawer">
    <router-outlet></router-outlet>
  </div>
</div>
