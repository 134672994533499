import { Component, ElementRef, Input, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-file-selector',
  templateUrl: './file-selector.component.html',
  styleUrls: ['./file-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileSelectorComponent),
      multi: true
    }
  ]
})
export class FileSelectorComponent implements ControlValueAccessor {
  @ViewChild('fileInput') fileInput!: ElementRef;

  @Input() label: string = 'Subir archivo';
  file: File | undefined;
  disabled: boolean = false;

  constructor() { }

  onChange: any = () => { };
  onTouch: any = () => { };

  writeValue(obj: any): void {
    this.file = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  openFileSelector(): void {
    this.fileInput.nativeElement.value = null;
    this.fileInput.nativeElement.click();
  }

  onSelectFile(event: Event): void {
    const files = (event.target as HTMLInputElement).files;
    const file = files?.item(0);
    if (file) {
      this.file = file;
      this.onChange(file);
      this.onTouch();
    }
  }

  removeSelectedFile(): void {
    this.file = undefined;
    this.onChange(undefined);
    this.onTouch();
  }
}
