import { Component, ElementRef, HostBinding, HostListener, Input, Renderer2, ViewChild } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: 'app-side-nav-item',
  templateUrl: './side-nav-item.component.html',
  styleUrls: ['./side-nav-item.component.scss'],
})
export class SideNavItemComponent {
  @ViewChild('hoverContent') hoverContent!: ElementRef;

  constructor(private router: Router, private renderer: Renderer2, private el: ElementRef) {}

  public isInSubGroup: boolean = false;

  @HostBinding('class.in-subgroup') get inSubGroupClass() {
    return this.isInSubGroup;
  }

  @HostListener('click', ['$event']) onClick(event: MouseEvent) {
    if (this.disabled) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      this.router.navigate([this.route]);
    }
  }

  @HostListener('mouseenter') onMouseEnter() {
    if (this.isInSubGroup) {
      this.updateMenuPosition();
    }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.isInSubGroup) {
      this.hideMenu();
    }
  }

  private updateMenuPosition() {
    const hoverContentElement = this.hoverContent.nativeElement as HTMLElement;
    const { top, right } = this.el.nativeElement.getBoundingClientRect();
    hoverContentElement.style.top = `${top + window.scrollY}px`;
    hoverContentElement.style.left = `${right + window.scrollX }px`;
    this.renderer.setStyle(hoverContentElement, 'display', 'block');
  }


  private hideMenu() {
    const hoverContentElement = this.hoverContent.nativeElement as HTMLElement;
    this.renderer.setStyle(hoverContentElement, 'display', 'none');
  }

  @Input() route: string = '';
  @Input() title: string = '';
  @Input() disabled: boolean = false;
  @Input() hasSubGroup: boolean = false;
}
