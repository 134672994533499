import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';
import { SpinnerService } from '../../spinner.service';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-section-with-side-panel',
  templateUrl: './section-with-side-panel.component.html',
  styleUrls: ['./section-with-side-panel.component.scss']
})
export class SectionWithSidePanelComponent implements OnInit {

  @ViewChild('sidePanel') sidePanel: MatDrawer | undefined;
  @Output() closedSidePanel = new EventEmitter<void>();

  constructor(public spinner: SpinnerService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
  }

  openSidePanel(): void {
    this.inactiveScrollMainLayout();
    this.sidePanel?.open();
  }

  closeSidePanel(): void {
    this.sidePanel?.close();
  }

  inactiveScrollMainLayout(): void {
    const element = document.querySelector('.main-section > .content');
    if (element) {
      element.classList.remove('content');
    }
  }

  activeScrollMainLayout(): void {
    const element = document.querySelector('.main-section .scroll-drawer');
    if (element) {
      element.classList.add('content')
    }
  }


  onDrawerClosed(): void {
    this.activeScrollMainLayout();
    const currentUrl = this.router.url;
    const urlSegments = currentUrl.split('/');
    const lastSegment = urlSegments[urlSegments.length - 1];
    const isID = this.isId(lastSegment);
    if (isID) {
      urlSegments.pop();
      const newUrl = urlSegments.join('/');
      this.router.navigate([newUrl], { relativeTo: this.route }).then();
    }
  }

// Función auxiliar para verificar si un segmento es un ID
  private isId(segment: string): boolean {
    return !isNaN(+segment);
  }

}
