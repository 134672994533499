import {AfterContentInit, Component, ContentChildren, HostBinding, Input, OnInit, QueryList} from '@angular/core';
import {CommonModule} from "@angular/common";
import {SideNavItemComponent} from "../side-nav-item/side-nav-item.component";

@Component({
  selector: 'app-side-nav-item-subgroup',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './side-nav-item-subgroup.component.html',
  styleUrl: './side-nav-item-subgroup.component.scss'
})
export class SideNavItemSubgroupComponent implements OnInit, AfterContentInit {
  @ContentChildren(SideNavItemComponent) items!: QueryList<SideNavItemComponent>;

  @HostBinding('class.open') get isOpen() {
    return this.status === 'open';
  }

  @HostBinding('class.collapsed') get isCollapsedClass() {
    return this.isCollapsed;
  }

  @Input() title: string = '';
  @Input() isCollapsed: boolean = false;
  @Input() status: 'open' | 'closed' = 'closed';

  constructor() { }

  ngOnInit(): void {}

  ngAfterContentInit() {
    this.items.forEach(item => {
      // Indicamos que este item es parte de un subgrupo
      item.isInSubGroup = true;
    });
  }

  onClickSubHeader(event: MouseEvent): void {
    this.status = this.status === 'open' ? 'closed' : 'open';
  }

}
