<header (click)="onClickHeader($event)">
  <div class="title-wrapper">
    <div class="title">
      <i *ngIf="_iconClass" [ngClass]="['icon', 'fa-solid', _iconClass]" [ngStyle]="getColorStyle()"></i>
      <span *ngIf="!isCollapsed">{{ title }}</span>
    </div>
    <i *ngIf="!isCollapsed" class="collapse-icon fa-solid fa-caret-down"></i>
  </div>
</header>
<div class="content">
  <ng-content></ng-content>
</div>
