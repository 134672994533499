import {Component, forwardRef, HostBinding, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StarRatingComponent),
      multi: true
    }
  ]
})
export class StarRatingComponent implements OnInit, ControlValueAccessor {

  @HostBinding('class.readonly') get readOnlyClass(): boolean {
    return this.readonly;
  }

  rate: number | null = 0;
  @Input() readonly: boolean = false;
  @Input() color: string = '#555';

  onChange = (_: any) => {};
  onTouch = () => {};

  highlighted: number | undefined;

  constructor() { }

  ngOnInit(): void {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.readonly = isDisabled;
  }

  writeValue(value: any): void {
    this.rate = value;
  }

  highlightTo(number: number) {
    if (!this.readonly) {
      this.highlighted = number;
    }
  }

  clearHighlight() {
    if (!this.readonly) {
      this.highlighted = undefined;
    }
  }

  isPositionEmpty(position: number): boolean {
    if (this.highlighted) {
      return this.highlighted < position;
    } else {
      return this.rate ? this.rate < position : true;
    }
  }

  setRate(rate: number) {
    if (!this.readonly) {
      this.rate = rate;
      this.onChange(this.rate);
    }
  }
}
